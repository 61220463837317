<template>
    <div class="container content">
        <div class="headline">
            <h1>{{ $t("message.partner.header") }}</h1>
        </div>
        <div class="tile is-ancestor">
            <div class="tile is-parent is-4">
                <article class="tile is-child box">
                    <p class="title">
                        {{ $t("message.partner.instructions.header") }}
                    </p>
                    <div v-html="$t('message.partner.instructions.txt')"></div>
                </article>
            </div>
            <div class="tile is-parent is-8">
                <article class="tile is-child box">
                    <p class="title">
                        1. {{ $t("message.partner.add.user.header") }}
                    </p>
                    <div class="columns">
                        <div class="column is-half">
                            <b-field
                                :label="
                                    $t('message.partner.add.user.firstname')
                                "
                            >
                                <b-input v-model="newUser.first_name"></b-input>
                            </b-field>

                            <b-field
                                :label="$t('message.partner.add.user.lastname')"
                            >
                                <b-input v-model="newUser.last_name"></b-input>
                            </b-field>

                            <b-field
                                :label="$t('message.partner.add.user.email')"
                                :type="emailFieldType"
                                :message="emailFieldMessage"
                            >
                                <b-input
                                    type="email"
                                    @blur="validateEmail"
                                    value=""
                                    v-model="newUser.email"
                                    :validation-message="
                                        $t('message.error.email-wrong-form')
                                    "
                                >
                                </b-input>
                            </b-field>
                        </div>
                        <div class="column is-half">
                            <b-field
                                :label="$t('message.partner.add.user.phone')"
                                :type="mobileFieldType"
                                :message="mobileFieldMessage"
                            >
                                <b-input v-model="newUser.mobile" @blur="validateMobile"></b-input>
                            </b-field>

                            <b-field
                                :label="$t('message.partner.add.user.password')"
                            >
                                <b-input
                                    type="password"
                                    value=""
                                    v-model="newUser.password"
                                    password-reveal
                                    :validation-message="
                                        $t('message.error.password-mismatch')
                                    "
                                >
                                </b-input>
                            </b-field>
                            <password
                                @input="validateFirstPassword"
                                v-model="newUser.password"
                                :strength-meter-only="true"
                            />
                            <b-field
                                :label="
                                    $t(
                                        'message.partner.add.user.confirm-password'
                                    )
                                "
                                :type="passwordFieldType"
                                :message="passwordFieldMessage"
                            >
                                <b-input
                                    @blur="validatePassword"
                                    type="password"
                                    value=""
                                    v-model="newUser.c_password"
                                    password-reveal
                                    :validation-message="
                                        $t('message.error.password-mismatch')
                                    "
                                >
                                </b-input>
                            </b-field>
                        </div>
                    </div>
                    <p class="title">
                        2. {{ $t("message.partner.add.company.header") }}
                    </p>
                    <div class="columns">
                        <div class="column is-half">
                            <b-field
                                :label="$t('message.partner.add.company.name')"
                            >
                                <b-input v-model="newPartner.name"></b-input>
                            </b-field>
                                                     
                        </div>
                        <div class="column is-half">
 <b-field
                                :label="
                                    $t(
                                        'message.partner.add.company.business-id'
                                    )
                                "
                                :type="vatFieldType"
                                :message="vatFieldMessage"
                            >
                                <b-input v-model="newPartner.vat" @blur="validateVAT"></b-input>
                            </b-field>
                    
                        </div>
                    </div>
                    <b-button
                        v-if="isValid && validator.email && validator.mobile"
                        :loading="false"
                        :label="$t('message.partner.add.send')"
                        size="is-large"
                        class="fullwidth"
                        type="is-success"
                        v-on:click="register"
                    />
                      <b-button
                        v-if="!isValid || !validator.email || !validator.mobile"
                        :loading="false"
                        :label="$t('message.partner.add.send')"
                        size="is-large"
                        class="fullwidth"
                        type="is-warning"
                        style="cursor: not-allowed;"
                    />
                </article>
            </div>
        </div>
    </div>
</template>

<script>
import axios from 'axios';
import Password from "vue-password-strength-meter";
export default {
    components: {
        Password,
    },
    data() {
        return {
            validator: {
                password: true,
                vat: true,
                email: true,
                mobile: true,
                message: {
                    email: "",
                    mobile: "",
                    password: "",
                    vat: ""
                }
            },
            user: false,
            newUser: {
                first_name: "",
                last_name: "",
                email: "",
                mobile: "",
                password: "",
                c_password: "",
                lang: "fi",
                education_partner_id: -1,
                type: "education_partner",
            },
            newPartner: {
                name: "",
                address: "",
                email: "",
                mobile: "",
                zip: "",
                city: "",
                vat: "",
                logo: "",
                www: "",
                //e_invoice_address: '',
                //e_invoice_provider: '',
                //billing_type: ''
            },
            errors: {
                is_error: false,
                first_name: false,
                last_name: false,
                email: false,
                mobile: false,
                password: false,
                c_password: false,
                name: false,
                address: false,
                zip: false,
                city: false,
                vat: false,
                logo: false,
                www: false,
                //e_invoice_address: false,
                //e_invoice_provider: false,
                //billing_type: false
            },
            burger: false,
            isComponentModalActive: false,
        };
    },
    computed: {
        passwordFieldType() {
            if(this.validator.password) {
                return "";
            }
            return "is-danger"
        },
        passwordFieldMessage() {
            if(this.validator.message.password != "") {
                return this.validator.message.password
            }
            return "";
        },
        vatFieldType() {
            if(this.validator.vat) {
                return "";
            }
            return "is-danger"
        },
        vatFieldMessage() {
            if(this.validator.message.vat != "") {
                return this.validator.message.vat
            }
            return "";
        },
        emailFieldType() {
            if(this.validator.email) {
                return "";
            }
            return "is-danger"
        },
        emailFieldMessage() {
            if(this.validator.message.email != "") {
                return this.validator.message.email
            }
            return "";
        },
        mobileFieldType() {
            if(this.validator.mobile) {
                return "";
            }
            return "is-danger"
        },
        mobileFieldMessage() {
            if(this.validator.message.mobile != "") {
                return this.validator.message.mobile
            }
            return "";
        },
        isValid() {
            if(this.newUser.first_name == "" || this.newUser.last_name == "") {
                return false;
            }
            if(this.newUser.email == "" || this.newUser.mobile == "") {
                return false;
            }
            if(this.newUser.password.length < 7 || this.newUser.password != this.newUser.c_password) {
                return false;
            }
            if(this.newPartner.name == "" || this.newPartner.vat == "") {
                return false;
            }
            return true;
        }
    },
    methods: {
        validateFirstPassword() {
            if(this.newUser.c_password != "") {
                this.validatePassword();
            }
        },
        validatePassword() {
           if(this.newUser.password == this.newUser.c_password && this.newUser.password.length > 6) {    
                this.validator.password = true;
                this.validator.message.password = "";
            } 
            else {
                this.validator.password = false;
                this.validator.message.password = this.$t("message.error.password-mismatch");
                if(this.newUser.password.length < 7) {
                    this.validator.message.password = this.$t("message.error.password-too-short");
                }
            }
        },
        validateEmail() {
            axios.post('users/validate-email',{ email: this.newUser.email}).then((response) => {
                if(response.data.success) {
                    this.validator.email = true;
                    this.validator.message.email = "";
                } else {
                    this.validator.email = false;
                    this.validator.message.email = this.$t("message.error."+response.data.msg);
                }
            })
        },
        validateMobile() {
            axios.post('users/validate-mobile',{ mobile: this.newUser.mobile}).then((response) => {
                if(response.data.success) {
                    this.validator.mobile = true;
                    this.validator.message.mobile = "";
                } else {
                    this.validator.mobile = false;
                    this.validator.message.mobile =  this.$t("message.error."+response.data.msg);
                }
            })
        },
        validateVAT() {
            axios.post('education-partners/validate-vat',{ vat: this.newPartner.vat}).then((response) => {
                if(response.data.success) {
                    this.validator.vat = true;
                    this.validator.message.vat = "";
                } else {
                    this.validator.vat = false;
                    this.validator.message.vat = this.$t("message.error."+response.data.msg);
                }
            })
        },
        createPartner() {
            axios.post('education-partners', this.newPartner).then((response) => {
                    var status = response.status;
                    if (status >= 200 && status < 300) {
                        var uu = this.newUser;
                        uu.education_partner_uuid = response.data.uuid;
                        this.$store
                            .dispatch("createUser", uu)
                            .then((response) => {
                                var status = response.status;
                                if (
                                    status >= 200 &&
                                    status < 300
                                ) {
                                    this.toastSuccess(
                                        this.$t(
                                            "message.partner.toaster.user.created"
                                        )
                                    );
                                    this.$router.push(
                                        "/hae-koulutuskumppaniksi-kiitos"
                                    );
                                } else {
                                    this.toastFailure(
                                        this.$t(
                                            "message.partner.toaster.user.creationError"
                                        )
                                    );
                                }
                            })
                            .catch((error) => {
                                this.ERROR(error);
                                this.toastFailure(
                                    this.$t(
                                        "message.partner.toaster.user.creationError"
                                    )
                                );
                                // TODO: rollback partner create
                            });
                    } else {
                        this.toastFailure(
                            this.$t(
                                "message.partner.toaster.company.creationError"
                            )
                        );
                    }
                })
                .catch((error) => {
                    this.ERROR(error);
                    this.toastFailure(
                        this.$t(
                            "message.partner.toaster.company.creationError"
                        )
                    );
                });
        },
        register() {
            if (this.newUser.password != this.newUser.c_password) {
                this.toastFailure(
                    this.$t("message.partner.toaster.user.password-mismatch")
                );
                return;
            }
            this.$store
                .dispatch("validatePartner", this.newPartner)
                .then((response) => {
                    console.log("validatePartner response", response);
                    this.$store
                        .dispatch("validateUser", this.newUser)
                        .then((_response) => {
                            console.log("validateUser _response", _response);
                            //var logo = this.newPartner.logo
                           // var uploadImg = (typeof logo !== 'undefined' || logo !== "") && (typeof logo !== 'string' || !(logo instanceof String))
                            //if (uploadImg) {
                                //console.log("creating file" + this.newPartner.logo.name)
                               // this.$store.dispatch("createLogo", this.newPartner.logo).then((_response) => {
                                 //   console.log("createLogo _response", _response)
                                   // var url = _response.data.img_url
                                   // this.newPartner.logo = url
                                    this.createPartner()
                              //  });
                           // } else {
                           //     this.createPartner()
                        //    }
                        })
                        .catch((error) => {
                            var displayed = false;
                            var msg = error.response.data;
                            for (const _key in msg.errors) {
                                this.toastFailure(
                                    this.$t("message.error.user." + _key)
                                );
                                displayed = true;
                            }
                            if (!displayed) {
                                this.toastFailure(
                                    this.$t(
                                        "message.register-company.toaster.user.creationError"
                                    )
                                );
                            }
                            console.log(error, error.response);
                        });
                })
                .catch((error) => {
                    var displayed = false;
                    var msg = error.response.data;
                    for (const _key in msg.errors) {
                        this.toastFailure(
                            this.$t("message.error.education-partner." + _key)
                        );
                        displayed = true;
                    }
                    if (!displayed) {
                        this.toastFailure(
                            this.$t(
                                "message.partner.toaster.company.creationError"
                            )
                        );
                    }
                    console.log(error, error.response);
                });
        },
    },
};
</script>